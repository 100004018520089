// Query ready. Not checked how it works together

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';

const PageTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const {
    title: pageTitle,
  } = data.contentfulPage;

  // const {
  //   description: pageDescription
  // } = data.contentfulPage.description

  const { html: pageBody } = data.contentfulPage.body.childMarkdownRemark;

  // const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;
  const metaDescription = siteSubtitle;

  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Page>
    </Layout>
  );
};

// export const query = graphql`
//   query PageBySlug($slug: String!) {
//     site {
//       siteMetadata {
//         title
//         subtitle
//       }
//     }
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       id
//       html
//       frontmatter {
//         title
//         date
//         description
//       }
//     }
//   }
// `;

export const query = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    contentfulPage(slug: {eq: $slug}) {
      id
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
}
`;

export default PageTemplate;